import React, {useState, useEffect} from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image"
import Layout from "../../components/Layout";
import SEO from "../../components/SEO";
import styled from "styled-components";
import Breadscrum from "../../components/Breadscrum";
import { useMediaQuery } from 'react-responsive';
import axios from "axios";
import Loading from "../../assets/img/loadingTransparent.svg";
import LinkButtonV2 from "../../components/brandingV2/LinkButtonV2";



const ParteInner = styled.div`
  .jumbotron {
    padding: 80px 0;
    padding-bottom: 90px;

    @media (max-width: 900px) {
      padding: 40px 0;
    }

    .jumboWrapper {
      max-width: 1150px;
      width: calc(100% - 100px);
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;

      @media (max-width: 900px) {
        display: block;
        width: calc(100% - 50px);
      }

      .left {
        max-width: 590px;
        width: calc(100% - 400px);

        @media (max-width: 900px) {
          width: calc(100%);
          max-width: none;
        }

        h1 {
          font-family: NewKansas;
          font-size: 42px;
          color: #243A58;
          margin-top: 15px;
          margin-bottom: 20px;

          @media (max-width: 900px) {
            font-size: 30px;
          }
        }

        p {
          font-family: Visuelt-Regular;
          font-size: 16px;
          color: #394B5C;
          line-height: 25px;
          margin-bottom: 40px;
        }

        .ctaCudlik {
          font-family: Visuelt-Medium;
          font-size: 18px;
          color: #000000;
          background: rgb(255, 238, 98);
          text-align: center;
          padding: 20px;
          width: calc(100% - 40px);
          max-width: 290px;
          display: block;
          border-radius: 5px;
          text-decoration: none;


          &:hover {
            background: #EFD83E;
          }
        }
      }

      .right {
        order: 2;

        @media (max-width: 900px) {
          width: calc(100%);
          max-width: none;
          margin-bottom: 30px;
        }

        .jumboImg {
          @media (max-width: 900px) {
          }
        }  
      }
    }
  }

  .seznamParte {
    background: #F7F7F7;
    padding-top: 70px;
    padding-bottom: 70px;

    @media (max-width: 900px) {
      padding: 35px 0;
    }

    .loadingImage {
      margin: 0 auto;
      display: block;
      height: 120px;
    }

    .seznamParteInner {
      ul {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        padding: 0;
        gap: 25px;
        max-width: 1150px;
        width: calc(100% - 100px);
        margin: 0 auto;

        @media (max-width: 1230px) {
          grid-template-columns: 1fr 1fr 1fr;
        }

        @media (max-width: 1000px) {
          grid-template-columns: 1fr 1fr;
        }

        @media (max-width: 650px) {
          grid-template-columns: 1fr;
          max-width: 380px;
          width: calc(100% - 50px);
          gap: 20px;
        }

        li {
          list-style: none;

          a {
            text-decoration: none;
          }
        }
      }
    }
  }

  .cta {
    padding-top: 80px;
    padding-bottom: 80px;

    h2 {
      font-family: Visuelt-Medium;
      font-size: 25px;
      color: #000000;
      text-align: center;
      width: calc(100% - 50px);
      margin: 0 auto;

      @media (max-width: 900px) {
        font-size: 22px;
        max-width: 340px;
      }
    }

    a {
      background: #3A557C;
      border-radius: 5px;
      font-family: Visuelt-Medium;
      font-size: 18px;
      color: #FFFFFF;
      text-align: center;
      padding: 20px 0;
      text-decoration: none;
      width: calc(100% - 50px);
      max-width: 310px;
      margin: 0 auto;
      display: block;
      margin-top: 30px;

      &:hover {
        background: #2A4164;
      }
    }
  }
`;



function datesToAge(deathDateO, birthDateO) {
  var deathDate = Date.parse(deathDateO);
  var birthDate = Date.parse(birthDateO);
  return (Math.round((deathDate - birthDate)/1000/60/60/24/365));
}

function dateToYear(dateO) {
  var date = Date.parse(dateO);
  date = new Date(date);
  return (date.getFullYear());
}


const PartePreviewInner = styled.div`
  background: #FFFFFF;
  box-shadow: 0 3px 10px 0 rgba(0,0,0,0.15);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 20px;
  height: 80px;

  .left {
    height: 60px;

    .obrazek {
      border-radius: 100%;
      width: 60px;
      height: 60px;
      overflow: hidden;
      position: relative;
      background: url(${props => props.imageUrl});
      background-position: center;
      background-size: cover;
    }
  }

  .right {
    width: calc(100% - 75px);

    .name {
      display: block;
      font-family: Visuelt-Medium;
      font-size: 18px;
      color: #000000;
      margin-bottom: 5px;
    }

    .info {
      display: block;
      font-family: Visuelt-Regular;
      font-size: 16px;
      color: #808080;
    }
  }
`;


const PartePreview = ({ imageUrl, name, deathDate, birthDate, slug }) => {
  
  const age = datesToAge(deathDate, birthDate);
  const deathYear = dateToYear(deathDate);
  const birthYear = dateToYear(birthDate);

  
  return (
    <li>
      <Link to={`/prt/${slug}/`}>
        <PartePreviewInner imageUrl={imageUrl}>
          <div className="left">
            <div className="obrazek">
            </div>
          </div>
          <div className="right">
            <span className="name">{name}</span>
            <span className="info">{birthYear} - {deathYear}, {age} let</span>
          </div>
        </PartePreviewInner>
      </Link>
    </li>
  )
};


const Parte = ({ data }) => {
  let backendUrl = "";

  if (process.env.NODE_ENV === 'development') {
    backendUrl = 'http://localhost:1337'
  }

  if (process.env.NODE_ENV === 'production') {
    backendUrl = 'https://goodbye-backend.herokuapp.com'
  }


  const isPlus900 = useMediaQuery({
    query: '(min-width: 900px)'
  });

  const [parteData, setParteData]  = useState([]);
  const [isLoading, setIsLoading]  = useState(false);


  useEffect(() => {
    setIsLoading(true);
    

    
    axios.get(`${backendUrl}/partes?_limit=-1`).then(res => {

      let parteContent = [];
      let index = 0;

      res.data.forEach(parte => {
        if (parte.isPublished === true && parte.profilePicture !== null && typeof parte.profilePicture !== "undefined") {
          index++;

          let fotka = parte.profilePicture.url;

          if (typeof parte.profilePicture.formats !== "undefined") {
            fotka = parte.profilePicture.formats.thumbnail.url;
          }


          parteContent.push(<PartePreview
            key={index}
            imageUrl={fotka}
            name={parte.name}
            deathDate={parte.deathDate}
            birthDate={parte.birthDate}
            slug={parte.slug}
          />)  
        }
      });

      setParteData(parteContent.reverse());
      setIsLoading(false);
    });

  }, []);


  


  return (
    <Layout
      customCtaText={"Vytvořit parte"}
      customCtaLink={"/vytvorit-parte/"}
    >
      <SEO
        title="Seznam vytvořených parte"
        description="Seznam všech online parte, která byla na naší stránce vytvořena. Vyhledejte parte Vašeho blízkého, sdílejte vzpomínky s ostatními a nebo vytvořte vlastní parte."
        image={"/parteOg.png"}
      />

      <ParteInner>

        


        <section className="jumbotron">
          <div className="jumboWrapper">
            <div className="right">
              {(isPlus900) ?
                <Img className="jumboImg" alt="Online parte" fixed={data.big.childImageSharp.fixed} />
              :
                <Img className="jumboImg" alt="Online parte" fixed={data.small.childImageSharp.fixed} />
              }
            </div>
            <div className="left">
              <Breadscrum
                visible={true}
                stranky={[
                  {name: "Domů", url: "/"},
                  {name: "Pohřební služba", url: "/pohrebni-sluzba/"},
                  {name: "Online parte", url: "/parte/"},
                  {name: "Poslední vytvořené", url: "/parte/posledni-vytvorene/"},
                ]}
              />
              <h1>Seznam vytvořených parte</h1>
              <p>Seznam všech online parte, která byla na naší stránce vytvořena. Vyhledejte parte Vašeho blízkého, sdílejte vzpomínky s ostatními a nebo vytvořte interaktivní parte pro Vašeho zesnulého sami.</p>
              <LinkButtonV2 to="/vytvorit-parte/" text="Vytvořit zdarma" />
            </div>
          </div>
          
        </section>
        <section className="seznamParte">
          <div className="seznamParteInner">
            {!isLoading ?
              <ul>
                {parteData}
              </ul>
            :
              <img className="loadingImage" src={Loading} alt="Načítání..." />
            }
          </div>
        </section>
        <section className="cta">
          <h2>Online parte dokáže vytvořit úplně každý již za 5 minut</h2>
          <Link to="/vytvorit-parte/">Vytvořit zdarma</Link>
        </section>
      </ParteInner>
    </Layout>
  );
}


export const query = graphql`
  query {
    small: file(relativePath: { eq: "jumboImage.png" }) {
      childImageSharp {
        fixed(width: 210) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    big: file(relativePath: { eq: "jumboImage.png" }) {
      childImageSharp {
        fixed(width: 350) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    parteSamplePic: file(relativePath: { eq: "parteSamplePic.png" }) {
      childImageSharp {
        fixed(width: 60) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
  }
`;

export default Parte;
